import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import GUI from 'lil-gui';

// Canvas
const canvas = document.querySelector('canvas.webgl');

const debugObject = {
    meshColor: '#fff31a',
    backgroundColor: '#ffffff',
    subdivision: 20,
    noiseAmp: 0.3,
    noiseFrequency: 2.0,
    noiseSpeed: 1.0,
    wireframe: false,
    fullscreen: () => {
        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;
        if (!fullscreenElement) {
            if (canvas.requestFullscreen) {
                canvas.requestFullscreen();
            } else if (canvas.webkitRequestFullscreen) {
                canvas.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
};

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(debugObject.backgroundColor);

// Shader// Vertex Shader for waveShaderMaterial
const vertexShader = `
uniform float uTime;
uniform float noiseAmp;
uniform float noiseFrequency;
uniform float noiseSpeed;
varying vec2 vUv;
varying vec3 vNormal;

//	Simplex 3D Noise 
//	by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}

float snoise(vec3 v){ 
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

// First corner
  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

// Other corners
  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  //  x0 = x0 - 0. + 0.0 * C 
  vec3 x1 = x0 - i1 + 1.0 * C.xxx;
  vec3 x2 = x0 - i2 + 2.0 * C.xxx;
  vec3 x3 = x0 - 1. + 3.0 * C.xxx;

// Permutations
  i = mod(i, 289.0 ); 
  vec4 p = permute( permute( permute( 
             i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
           + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
           + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

// Gradients
// ( N*N points uniformly over a square, mapped onto an octahedron.)
  float n_ = 1.0/7.0; // N=7
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

//Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

// Mix final noise value
  vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                dot(p2,x2), dot(p3,x3) ) );
}


void main() {
    vUv = uv;
    vNormal = normal; 
    vec3 pos = position;
    vec3 worldPos = (modelMatrix * vec4(pos, 1.0)).xyz;
    
    if (position.z >= 0.0) {
        float noise = snoise(vec3(worldPos.x * noiseFrequency, 
                                  worldPos.y * noiseFrequency, 
                                  uTime * noiseSpeed));
        pos.z += noise * noiseAmp * position.z;
    }
    
    float wave = sin(worldPos.z * noiseFrequency * 5.0f + uTime * 5.0f) * 0.075f; //frequency of wave, speed of wave, amount of wave
    pos.y += wave;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
}
`;


// Fragment Shader for waveShaderMaterial
const fragmentShader = `
precision highp float;

varying vec2 vUv;
varying vec3 vNormal;
uniform vec3 uColor;
uniform float uTime;
uniform float noiseSpeed;

void main() {
    float animTime = uTime * noiseSpeed;
    float sineValue = sin(vUv.x + animTime);
    float alpha = 0.5 * (1.0 + sineValue);
    if (vNormal.z > 0.0) {
        alpha = 1.0;
    }
    alpha = clamp(alpha, 0.025, 1.0);
    
    gl_FragColor = vec4(uColor, alpha);
}
`;


// Shader Material
const waveShaderMaterial = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
        uTime: { value: 0.0 },
        uColor: { value: new THREE.Color(debugObject.meshColor) },
        noiseAmp: { value: debugObject.noiseAmp },
        noiseFrequency: { value: debugObject.noiseFrequency },
        noiseSpeed: { value: debugObject.noiseSpeed }
    },
    wireframe: debugObject.wireframe,
    transparent: true
});

// Cube Geometry
const geometry = new THREE.BoxGeometry(1, 1, 1, debugObject.subdivision, debugObject.subdivision, debugObject.subdivision);
const cube = new THREE.Mesh(geometry, waveShaderMaterial);
cube.scale.set(1, 1, 1);
cube.position.y = 1.25;
cube.rotateY(-Math.PI * 0.25);
scene.add(cube);


// Function to create text meshes
const createTextMeshes = (text, font, size, depth, material, yOffset) => {
    const textMeshes = [];
    let offsetX = 0;
    const spaceWidth = 0.2 * size;

    for (let i = 0; i < text.length; i++) {
        const char = text[i];

        if (char === ' ') {
            offsetX += spaceWidth;
            continue;
        }

        const charGeometry = new TextGeometry(char, {
            font: font,
            size: size,
            depth: depth,
            curveSegments: 5,
            bevelEnabled: true,
            bevelThickness: 0.0,
            bevelSize: 0.0,
            bevelOffset: 0,
            bevelSegments: 4
        });

        charGeometry.computeBoundingBox();
        const charWidth = charGeometry.boundingBox.max.x - charGeometry.boundingBox.min.x;
        const charMesh = new THREE.Mesh(charGeometry, material);
        charMesh.position.set(offsetX, yOffset, 0);

        offsetX += charWidth;

        textMeshes.push(charMesh);
    }

    const totalWidth = offsetX;
    textMeshes.forEach(mesh => {
        mesh.position.x -= totalWidth / 2;
        scene.add(mesh);
    });

    return textMeshes;
};

let volumetricMeshes = [];
let websiteMeshes = [];

// Fonts
const fontLoader = new FontLoader();
fontLoader.load('/fonts/Poppins_SemiBold_Italic.json', (font) => {
    const material = new THREE.MeshStandardMaterial({ color: 0xef3743, transparent: true });
    volumetricMeshes = createTextMeshes('Volumetric', font, 0.5, 0.2, material, 0);
    websiteMeshes = createTextMeshes('Website', font, 0.5, 0.2, new THREE.MeshStandardMaterial({ color: 0xef3743 }), -1.2);
});

const ambientLight = new THREE.AmbientLight(0xffffff, 2.0);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 2.0);
directionalLight.position.set(0, 10, 7.5);
scene.add(directionalLight);

// Size
const size = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    size.width = window.innerWidth;
    size.height = window.innerHeight;

    camera.aspect = size.width / size.height;
    camera.updateProjectionMatrix();

    renderer.setSize(size.width, size.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

// Camera
const camera = new THREE.PerspectiveCamera(45, size.width / size.height);
camera.position.z = 9;
scene.add(camera);

// Renderer
const renderer = new THREE.WebGLRenderer({ canvas: canvas });
renderer.setSize(size.width, size.height);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

// Clock
const clock = new THREE.Clock();

// Function to remove existing text meshes with animation
const removeTextMeshesWithAnimation = (meshes, duration = 0.5) => {
    meshes.forEach(mesh => {
        const initialZ = mesh.position.z;
        const targetZ = initialZ - 3;
        const initialOpacity = mesh.material.opacity;
        const targetOpacity = 0;
        const startTime = clock.getElapsedTime();
        
        const animateOut = () => {
            const elapsedTime = clock.getElapsedTime() - startTime;
            const progress = elapsedTime / duration;
            if (progress < 1) {
                mesh.position.z = initialZ + (targetZ - initialZ) * progress;
                mesh.material.opacity = initialOpacity + (targetOpacity - initialOpacity) * progress;
                mesh.position.y = Math.sin(progress * Math.PI * 2) * 0.2 - 0.1;
                requestAnimationFrame(animateOut);
            } else {
                scene.remove(mesh);
                mesh.geometry.dispose();
                mesh.material.dispose();
            }
        };
        animateOut();
    });
};

let debounceTimer;

// Function to update the text mesh multiple times
const updateTextMeshMultipleTimes = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
        const newText = document.getElementById('textInput').value.trim();
        if (newText) {
            for (let i = 0; i < 10; i++) {
                setTimeout(() => {
                    removeTextMeshesWithAnimation(volumetricMeshes, 1.0);
                    const material = new THREE.MeshStandardMaterial({ color: 0xef3743, transparent: true });
                    fontLoader.load('/fonts/Poppins_SemiBold_Italic.json', (font) => {
                        volumetricMeshes = createTextMeshes(newText, font, 0.5, 0.2, material, 0);
                    });
                }, i * 50);
            }
        }
    }, 100);
};

// Update text on button click
document.getElementById('updateText').addEventListener('click', updateTextMeshMultipleTimes);

// Update text on return key press
document.getElementById('textInput').addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
        updateTextMeshMultipleTimes();
    }
});

// Animations
const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    waveShaderMaterial.uniforms.uTime.value = elapsedTime;

    volumetricMeshes.forEach((mesh, index) => {
        const amplitude = 0.075;
        const frequency = 2;
        mesh.position.y = amplitude * Math.sin(frequency * elapsedTime + index);
        mesh.position.z = amplitude * Math.cos(frequency * elapsedTime + index);
    });

    websiteMeshes.forEach((mesh, index) => {
        const amplitude = 0.075;
        const frequency = 2;
        mesh.position.y = amplitude * Math.sin(frequency * elapsedTime + index) - 0.7;
        mesh.position.z = amplitude * Math.cos(frequency * elapsedTime + index);
    });

    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
};

tick();
